<template lang="pug">
  include ../../../configs/template.pug
  div
    div(v-if="previewInformation.documents_exists.length").text-left
      label.mt-1 {{ $t('existsDocs') }}:
      TableV2(
        :headers="fieldsExistDocuments"
        :items="previewInformation.documents_exists"
        isHiddenTableCount
        isHiddenPageCount
        isHiddenTableSearch)
    div(v-if="previewInformation.documents_not_exists.length").text-left
      label.mt-1 {{ $t('notExistsDocs') }}:
      TableV2(
        :headers="fieldsNotExistDocuments"
        :items="previewInformation.documents_not_exists"
        isHiddenTableCount
        isHiddenPageCount
        isHiddenTableSearch)
</template>

<script>
import { getStatus, getExperienceStatus } from '@/mixins/main'
import { mapActions, mapGetters, mapState } from 'vuex'
export default {
  props: {
    invalidPackageInfo: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      fieldsExistDocuments: [
        { value: 'type_doc', text: this.$t('typeDoc') },
        { value: 'number', text: this.$t('number') },
        { value: 'info', text: this.$t('position'), tdClass: 'ellipsis' },
        { value: 'name_issued', text: this.$t('issued'), tdClass: 'ellipsis' },
        { value: 'date_start', text: this.$t('dateEffective') },
        { value: 'date_end', text: this.$t('dateTermination') },
        { value: 'status_is_verification', text: this.$t('status') }
      ],
      fieldsNotExistDocuments: [
        { value: 'type_document', text: this.$t('typeDoc') },
        { value: 'document_descr', text: this.$t('nameDoc') },
        { value: 'standarts_text', text: this.$t('requirements') },
        { value: 'selectItem', text: '', width: '75%' }
      ],
      fieldsExperience: [
        { key: 'experience_descr', label: this.$t('experience') },
        { key: 'standarts_text', label: this.$t('standards') },
        { key: 'status', label: this.$t('status') }
      ],
      getExperienceStatus,
      getStatus,
      listMedicalInstitution: []
    }
  },
  computed: {
    ...mapGetters(['educationTrainingInstitutionByID']),
    ...mapState({
      labelName: state => state.main.lang === 'en' ? 'name_eng' : 'name_ukr',
      listMedicalPosition: state => state.directory.positionMedical,
      medInstitusionList: state => state.directory.medInstitution
    })
  },
  methods: {
    ...mapActions(['getMedicalInstitutionForPacket']),
    async getMedicalInstitutionList (id) {
      const response = await this.getMedicalInstitutionForPacket(id)
      this.listMedicalInstitution = response
    }
  },
  beforeMount () {
    this.previewInformation = this.invalidPackageInfo
    if (this.previewInformation.can_create_packet && this.previewInformation.documents_not_exists?.some(item => ['Свідоцтво НТЗ', 'Сертифікат НТЗ'].includes(item.type_document))) {
      this.fieldsNotExistDocuments.push({ key: 'institutionSelect', label: this.$t('institution') }) // Add table column with eti selection
      this.previewInformation.documents_not_exists.map(item => {
        if (['Свідоцтво НТЗ', 'Сертифікат НТЗ'].includes(item.type_document)) {
          item.institution = null // Set empty institution field
          item.available_ntz = item.available_ntz.map(institution => this.educationTrainingInstitutionByID(institution)) // Transform id's list to list of objects
        }
        return item
      })
    }
    if (this.previewInformation.can_create_packet && this.previewInformation.documents_not_exists?.some(item => item.type_document === 'Медичне свідоцтво')) {
      this.fieldsNotExistDocuments.push({ key: 'medicalSelect', label: this.$t('medical') }) // Add table column with eti selection
      this.previewInformation.documents_not_exists.map(item => {
        if (item.type_document === 'Медичне свідоцтво') {
          item.medical_institution = null // Set empty medical institution field
          item.medical_position = null // Set empty medical position field
          item.available_medical_position = this.listMedicalPosition.filter(el => item.available_medical_position.includes(el.id))// Transform id's list to list of objects
        }
        return item
      })
    }
  }
}
</script>
